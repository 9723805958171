@import 'app/styles/typography';
@import 'app/styles/colors';


.custom-container {  
  word-break: break-word;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #212121;
  text-align: center;
  font-family: 'Proxima Nova', 'Roboto', 'Lato', sans-serif !important;
  @media (max-width: 500px) {
    text-align: left;
    color: #757575;
  } 
}
.custom-container strong {
  text-align: center;
  color: #0069A7;
  text-align: center;
  @media (max-width: 500px) {
    
    color: #065394;
  } 
}
.custom-container em {
  font-style: italic;
  color: #0069A7;
  @media (max-width: 500px) {
    
    color: #065394;
  }
  /* Estilos adicionales para el contenido en cursiva */
}

.title-container {
  
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #055293;
  text-align: center;
  font-family: 'Proxima Nova', 'Roboto', 'Lato', sans-serif !important;
  @media (max-width: 500px) {
    text-align: left;
    color: #055293;
  }
 
}
.title-container strong {
  text-align: center;
  color: #055293;
  text-align: center;
  @media (max-width: 500px) {
    
    color: #055293;
  }
  
  
}

.title-container em {
  font-style: italic;
  color: #055293;
  @media (max-width: 500px) {
    
    color: #055293;
  }
  /* Estilos adicionales para el contenido en cursiva */
}

.title-container-dark {
  
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #757575;
  text-align: center;
  font-family: 'Proxima Nova', 'Roboto', 'Lato', sans-serif !important;
  @media (max-width: 500px) {
    text-align: left;
    color: #757575;
  }
 
}
.title-container-dark strong {
  text-align: center;
  color: #757575;
  text-align: center;
  @media (max-width: 500px) {
    
    color: #757575;
  }
  
  
}

.title-container-dark em {
  font-style: italic;
  color: #757575;
  @media (max-width: 500px) {
    
    color: #757575;
  }
  /* Estilos adicionales para el contenido en cursiva */
}

.preview-container {
  justify-content: left;
  align-items: left;
  font-size: 14px;
  color: #212121;
  text-align: left;
  font-family: 'Proxima Nova', 'Roboto', 'Lato', sans-serif !important;
  @media (max-width: 500px) {
    text-align: left;
    color: #5b5c5b;
  }
 
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word !important; 
}

.preview-container strong {
  text-align: center;
  color: #212121;
  text-align: center;
  @media (max-width: 500px) {
    
    color: #5b5c5b;
  }
  
  
}

.preview-container em {
  font-style: italic;
  color: #212121;
  @media (max-width: 500px) {
    
    color: #5b5c5b;
  }
  /* Estilos adicionales para el contenido en cursiva */
}
@import 'app/styles/colors';
@import 'app/styles/typography';

.parent {
  background: white;
  width: 100px;
  height: 100px;
  display: -webkit-flex;
  -webkit-justify-content: center;
  justify-content: center;
  align-items: center;
  bottom: 8% !important;

  p {
    color: $color-blue-dc1;
    text-align: right;
    font-size: 16px;
    line-height: 19.5px;
  }
}

@media (max-width: 500px) {
  .parent[data-isopen='true'] {
    right: -15% !important;
    bottom: -5% !important;
  }
}

.floatingMenuIcon,
.floatingMenuIconX {
  position: absolute;
}

.floatingChatIcon {
  position: absolute;
  left: 10px;
  top: 14px;
}

.parent[data-isopen='true'] {
  width: 370px;
  height: 370px;
  z-index: 5;
  box-shadow: 0px 4px 4px #c4c4c4;

  .floatingMenuIcon {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
  }

  .floatingMenuIconX {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
  }
}

.parent[data-isopen='false'] {
  .floatingMenuIcon {
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
  }

  .floatingMenuIconX {
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -ms-transition: transform 0.5s;
    -o-transition: transform 0.5s;
  }
}

.child {
  width: 40px;
  height: 40px;
  background: #3ca70d;
  border-radius: 50%;
}

.circular-menu-container {
  position: fixed;
  right: -100px;
  bottom: -22px;
  display: -webkit-flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 400px;
  height: 400px;
  -webkit-align-items: center;
  align-items: center;
}

#label-container {
  position: absolute;
  transition: box-shadow 0.15s ease-in-out, border 0.15s ease-in-out;
  -webkit-transition: box-shadow 0.15s ease-in-out, border 0.15s ease-in-out;
  -moz-transition: box-shadow 0.15s ease-in-out, border 0.15s ease-in-out;
  -ms-transition: box-shadow 0.15s ease-in-out, border 0.15s ease-in-out;
  -o-transition: box-shadow 0.15s ease-in-out, border 0.15s ease-in-out;
  pointer-events: all;
  will-change: transform;
  height: 35px;
  display: -webkit-flex;
  //justify-content: center;
  //-webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}

#icon-container {
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  background-color: 'white';
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-box-shadow: 0px 4px 4px #c4c4c4;
  box-shadow: 0px 4px 4px #c4c4c4;
  cursor: pointer;
  transition: box-shadow 0.15s ease-in-out, border 0.15s ease-in-out;
  -webkit-transition: box-shadow 0.15s ease-in-out, border 0.15s ease-in-out;
  -moz-transition: box-shadow 0.15s ease-in-out, border 0.15s ease-in-out;
  -ms-transition: box-shadow 0.15s ease-in-out, border 0.15s ease-in-out;
  -o-transition: box-shadow 0.15s ease-in-out, border 0.15s ease-in-out;
  pointer-events: all;
  will-change: transform;
  margin-left: -22%;
}
$font-size-D1: 3.75rem;
$font-size-D2: 3.75rem;
$font-size-D3: 3rem;
$font-size-h1: 2.625rem;
$font-size-h2: 2.25rem;
$font-size-h3: 2rem;
$font-size-h4: 1.75rem;
$font-size-h5: 1.5rem;
$font-size-h6: 1.25rem;
$font-size-subtitle: 1.125rem;
$font-size-body1: 1.125rem;
$font-size-body2: 1rem;
$font-size-body3: 0.875rem;
$font-size-bt: 1.125rem;
$font-size-caption: 0.875rem;
$font-size-overline: 0.875rem;

.font-regular {
  font-family: 'Proxima Nova', 'Roboto', 'Lato', sans-serif;
}

.font-bold {
  font-family: 'Proxima Nova bold', 'Roboto bold', 'Lato bold', sans-serif;
}

@mixin mix-Display-1 {
  font-size: $font-size-D1;
  @extend .font-bold;
}

@mixin mix-Display-2 {
  font-size: $font-size-D1;
  font-family: 'Proxima Nova black', 'Roboto black', 'Lato black', sans-serif;
}

@mixin mix-Display-3 {
  font-size: $font-size-D1;
  font-weight: 400;
}

@mixin mix-headline-1 {
  font-size: $font-size-h1;
}

@mixin mix-headline-2 {
  font-size: $font-size-h2;
}

@mixin mix-headline-3 {
  font-size: $font-size-h3;
}

@mixin mix-headline-4 {
  font-family: 'Proxima Nova', 'Roboto', 'Lato', sans-serif;
  font-size: $font-size-h4;
  font-weight: bold;
}

@mixin mix-headline-5 {
  @extend .font-bold;
  font-size: $font-size-h5;
}

@mixin mix-headline-6 {
  font-size: $font-size-h6;
  @extend .font-bold;
}

@mixin mix-subtitle {
  font-size: $font-size-subtitle;
}

@mixin mix-body-1 {
  font-size: $font-size-body1;
}

@mixin mix-body-2 {
  font-size: $font-size-body2;
}

@mixin mix-body-3 {
  font-size: $font-size-body3;
}

@mixin mix-button-txt-size {
  font-size: $font-size-bt;
  text-transform: uppercase;
  @extend .font-bold;
}

@mixin mix-caption-txt-size {
  font-size: $font-size-caption;
}

@mixin mix-overline-txt-size {
  font-size: $font-size-caption;
  text-transform: uppercase;
}

.font-size-D1 {
  @include mix-Display-1();
}

.font-size-D2 {
  @include mix-Display-2();
}

.font-size-D3 {
  @include mix-Display-3();
}

.font-size-h1 {
  @include mix-headline-1();
}

.font-size-h2 {
  @include mix-headline-2();
}

.font-size-h3 {
  @include mix-headline-3();
}

.font-size-h4 {
  @include mix-headline-4();
}

.font-size-h5 {
  @include mix-headline-5();
}

.font-size-h6 {
  @include mix-headline-6();
}

.font-size-body1 {
  @include mix-body-1();
}

.font-size-body2 {
  @include mix-body-2();
}

.font-size-body3 {
  @include mix-body-3();
}

.font-size-bt {
  @include mix-button-txt-size();
}
.font-size-caption {
  @include mix-caption-txt-size();
}
.font-size-overline {
  @include mix-overline-txt-size();
}

.line-h {
  line-height: 20px;
}

.bold {
  @extend .font-bold;
}

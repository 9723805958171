
// start KeraltyTooltip.tsx
.__react_component_tooltip{
    // box-shadow: rgb(184 184 184 / 25%) 0px 2px 4px;
    border-radius: 8px;
    padding: .5rem;
    max-width: 17rem;
    font-size: 1.2rem;
    * {
        font-size: .8rem;
    }
}

// end KeraltyTooltip.tsx
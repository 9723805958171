@import "app/styles/typography.scss";
@import "app/styles/colors.scss";

.login-form {
  // height: 100%;
  // display: grid;
  // grid-template-columns: minmax(100%, 1fr);
  // grid-template-rows: 0.4fr 1fr 0.2fr;
  // grid-template-areas: "l-form-header" "l-form-body" "l-form-footer";

  &-header {
    display: flex;
    justify-content: center;
  }

  &-body {
    // grid-area: l-form-body;
    text-align: center;
    form {
      .send-button {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }

    .forgot-pass {
      margin-top: 36px;
    }
    .create-account {
      margin-top: 15px;
    }
  }

  &-footer {
    // grid-area: l-form-footer;
  }
}

@import './app/styles/typography.scss';
@import './app/styles/breakpoints.scss';
@import './app/styles/colors.scss';

/*html,
body {
  position: absolute;
  overflow: hidden;
  height: 100vh;
}
#root {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

MERGE MOBILE*/

body {
  @media only screen and (max-width: 900px)  {
    background: #f5f5f5
  }
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

div.grecaptcha-badge {
  bottom: 40% !important;
  z-index: 2;

  @media only screen and (max-width: 900px)  {
    bottom: 10% !important;
  }
}

#sensely-custom_wrapper {
  height: 90vh;
  max-width: 375px;
  width: 90vw;
  position: absolute;
  right: 5%;
  top: 5%;
  bottom: 5%;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 10;
  flex-direction: column;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25) !important;
  overflow-y: overlay;

  #sensely-custom_header {
    position: relative;
    height: 50px;
    z-index: 2;
    display: flex;
    justify-content: left;
    .bg-container {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      align-self: flex-start;
      .profile-pic {
        height: 30px;
        width: 30px;
        margin-left: 20px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
      }
      .sensely-custom-title {
        color: white;
        font-size: 1.4rem;
        position: absolute;
        left: 15px;
        font-weight: normal;

        // Safari Hack
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            font-weight: bold;
          }
        }
      }
      .sensely-custom-controls {
        padding-right: 20px;
        width: 40px;
        display: flex;
        justify-content: space-between;
        .sensely-header-icon-container {
          cursor: pointer;
        }
      }
    }
    .sensely-custom-header-bg {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .sensely-content_filled {
    display: flex;
    order: 1;
    flex-direction: column;
  }

  @keyframes changePosition {
    0% {
      justify-content: center;
    }
    50% {
      justify-content: normal;
    }
  }

  .sensely-chat-loader {
    order: 2;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: normal;
    align-self: center;
    flex-direction: column;
    animation-name: changePosition;
    animation-duration: 1s;
  }
}
.chat-minimized {
  height: 0 !important;
}

.chat-hidden {
  display: none !important;
}

.footer {
  grid-area: footer;
  background-color: gray;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pre-login-header {
  height: 68px;
  background-color: $color-blue-dc1;
  .pre-login-logo {
    margin-left: 1.5rem;
    margin-top: 10px;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}

.pre-login-header-arc {
  height: 181px;
  z-index: 1;
  background-color: transparent; 
  position: fixed;
  width: 100%;
  background-image: url('./app/assets/images/login-header.png');
  background-position: center bottom;
  background-size: cover;background-repeat: no-repeat;
  .pre-login-logo {
    margin-left: calc(50% - 103.5px);
    margin-top: 3rem;
    height: 95px;
  }
}
.right-side-page-arc {
  @media (min-width: 320px) and (max-width: 599px) {
    // ARC HEADER MOBILE & RESPONSIVE
    position: fixed !important;
    top: 166px;
  }
}

.pre-login {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 2.2fr 0.3fr;
  gap: 0px 0px;
  grid-template-areas:
    'pre-login-header pre-login-header pre-login-header'
    'pre-login-body pre-login-body pre-login-body'
    'pre-login-footer pre-login-footer pre-login-footer';

  & div {
    grid-column: 1/-1;
  }

  &-header {
    grid-area: pre-login-header;
    @extend .pre-login-header;
  }

  &-body {
    display: grid;
    grid-area: pre-login-body;
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 1.5fr 0.5fr;
    gap: 0px 0px;
    grid-template-areas:
      'title'
      'input'
      '.';
    height: 75vh;
    justify-content: center;
    .title {
      grid-area: title;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      h3,
      p {
        margin: 0;
        color: $color-blue-dc1;
      }
    }
    .input-container {
      margin-top: 35px;
      grid-area: input;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .btn-continue-wrapper {
        margin-top: 35px;
      }
    }
  }

  &-footer {
    grid-area: pre-login-footer;
    margin-bottom: 0;
  }
}

.vim-provider-discovery-widget-root.cordova .vim-widget.large {
    padding-top: 3em;
    height: calc(100% - 3em);
  }
@media (min-width: 320px) and (max-width: 600px) {
  .pre-login-body .title {
    margin-top: 50%;
  }

}
.MuiAccordionSummary-root > .Mui-expanded:nth-child(2) {
  transform: rotate(90deg);
}

.item {
  margin-bottom:5px;
  padding: 10px 20px;
  max-height: '100%';

}

.title{

  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-left: -10px;
}

.content {
   overflow: hidden;
   transition: all 0.5s cubic-bezier(0,1,0,1);
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1,0,1,0);
}
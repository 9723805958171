@import "app/styles/typography";
@import "app/styles/colors";

.login {
  height: 100vh;
  width: 100vw;
  @media (max-width: 768px) {
    grid-template-columns: minmax(calc(min(50%, 25rem)), 1fr);
  }

  .pre-login-header {
    // height: 68px;
    // z-index: 3;
  }

  &-body {
    display: flex;
    height: calc(100vh - 68px);
    width: 100vw;
    overflow: hidden;
    &.arc {
      @media (min-width: 320px) and (max-width: 599px) {
        // ARC HEADER MOBILE & RESPONSIVE
        height: calc(100vh - 151px);
        padding-top: 151px;
      }
    }
    @media (max-width: 768px) {
      .text-container {
        margin-top: 0%;
        margin-bottom: 3%;
      }
    }
    .login-left-side {
      background-color: $color-blue-dc1;
      width: 50%;

      .text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        margin-top: 5%;
        margin-bottom: 7%;
        .main-text {
          margin: 0;
          margin-top: 6rem;
          font-size: 3.33rem;
          color: white;
        }
        .divider {
          margin-top: 1.33rem;
          border-bottom: 1px solid white;
          width: 29%;
          align-self: baseline;
          // margin-left: 17%;
        }
      }
      .icons-container {
        // height: 54%;
        justify-self: center;
        display: flex;
        justify-content: center;
        z-index: 5;
        position: relative;
        .wrapper {
          max-width: 70%;
          height: 60%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          grid-template-areas: "first-row first-row first-row";
          place-items: center;
        }
        .icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100px;
          margin-bottom: 2.5rem;
          .icon-desc {
            margin: 1rem 0 0 0;
            color: white;
            text-align: center;
            width: 73%;
            font-size: 1.17rem;
          }
        }
      }

      .stores-container {
        position: fixed;
        right: 53%;
        bottom: 2%;
        width: 21%;
        display: flex;
        justify-content: space-around;
        z-index: 4;
      }

      .abstract-wrapper {
        position: fixed;
        bottom: -20px;
        left: -2%;
      }

      @media (max-width: 990px) {
        display: none;
      }
    }

    .login-right-side {
      width: 50%;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;
      position: relative;
      .abstract-header {
        position: absolute;
        z-index: 1;
        top: -35px;
        left: -7%;
        height: 54px;
        width: 590px;
        max-width: 100%;
        @media (max-width: 990px) {
          top: -30px;
        }
      }

      .children-wrapper {
        height: 100%;
        width: 100%;
        position: relative;
        .no-margin-top {
          .page-title{
            margin-top: 10px !important;
          }
        }
        .stores-container {
          @media (min-width: 990px) {
            display: none;
          }
          position: absolute;
          bottom: 2rem;
          width: 80%;
          display: flex;
          justify-content: center;
          div:first-of-type {
            margin-right: 20px;
          }
        }
      }

      @media (max-width: 990px) {
        width: 100%;
        top: 11px;
      }
    }

    &-footer {
      grid-area: login-footer;
      margin-bottom: 0;
    }
  }
}

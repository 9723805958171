// Primary Colors
$color-blue-dc1: #055293;
$color-blue-dc2: #055293;
$color-green-dc1: #3ca70d;
$color-gray-dc1: #5b5c5b;
$color-gray-dc2: #e5e5e5;
$color-gray-a3: #a3a3a3;

// Secondary colors
$color-blue-dc3: #0079de;
$color-blue-dc4: #49c3b1;
$color-green-dc2: #00b288;
$color-green-dc3: #90a800;

// Alerts and Notification Colors
$color-error: #d74840;
$color-success: #b2c44a;
$color-warning: #edc536;

// colors in style guide document
$color-blue-288: #022f57;
$color-blue-307: #0069a7;
$color-white: #ffffff;
$color-green-362: #4c9c2e;
$color-blue-306: #00b2e3;
$color-green-376: #80bc00;
$text-color-light: #212121;
$text-color-dark: #757575;

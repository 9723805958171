/* Proxima Nova Font */
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url('./app/assets/fonts/proxima_nova/proxima-nova-regular.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova thin';
  src: local('Proxima Nova thin'),
    url('./app/assets/fonts/proxima_nova/proxima-nova-thin.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova light';
  src: local('proxima-nova-light'),
    url('./app/assets/fonts/proxima_nova/proxima-nova-light.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova bold';
  src: local('Proxima Nova bold'),
    url('./app/assets/fonts/proxima_nova/proxima-nova-bold.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova black';
  src: local('Proxima Nova black'), url('./app/assets/fonts/proxima_nova/proxima-nova-black.otf') format('otf');
}

/* Roboto Font */
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./app/assets/fonts/roboto/roboto-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto thin';
  src: local('Roboto thin'),
    url('./app/assets/fonts/roboto/roboto-thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto light';
  src: local('roboto-light'),
    url('./app/assets/fonts/roboto/roboto-light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto bold';
  src: local('Roboto bold'),
    url('./app/assets/fonts/roboto/roboto-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto black';
  src: local('Roboto black'), url('./app/assets/fonts/roboto/roboto-black.ttf') format('truetype');
}

/* Lato Font */
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('./app/assets/fonts/lato/lato-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato thin';
  src: local('Lato thin'),
    url('./app/assets/fonts/lato/lato-thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato light';
  src: local('Lato-light'),
    url('./app/assets/fonts/lato/lato-light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato bold';
  src: local('Lato bold'),
    url('./app/assets/fonts/lato/lato-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato black';
  src: local('Lato black'), url('./app/assets/fonts/lato/lato-black.ttf') format('truetype');
}

/* Loto Font */

/* MOBILE */
@media (min-width: 320px) and (max-width: 1023px) {
  body,
  html {
    font-size: 10px !important;
    
  }
  input {
    font-family: 'Proxima Nova' !important;
    font-size: 1.5rem !important;
  }

  .react-datepicker {
    max-width: 308px !important;
    /* height: 273px !important; */
    height: auto;
  }

  .react-datepicker__day-names div.react-datepicker__day-name {
    width: 39px !important;
    height: 28px !important;
    display: inline-block;
    line-height: 28px;
    text-align: center;
    margin: 0.166rem;
  }

  div.react-datepicker__week div.react-datepicker__day {
    display: inline-block;
    text-align: center;
    margin: 0.166rem;
    width: 39px !important;
    height: 24px !important;
    line-height: 24px !important;
  }

  div.react-datepicker__year div.react-datepicker__year-wrapper {
    max-width: max-content;
  }

  div.react-datepicker__year div.react-datepicker__year-wrapper .react-datepicker__year-text {
    font-size: 14px !important;
    min-width: 28% !important;
    text-align: center;
    height: 28px !important;
    line-height: 28px !important;
    width: max-content !important;
  }

  div.react-datepicker__month-wrapper {
    max-width: max-content;
  }

  div.react-datepicker__month-wrapper .react-datepicker__month-text {
    font-size: 14px !important;
    width: 82px !important;
    height: 28px !important;
    line-height: 28px !important;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  span.month,
  span.year {
    font-family: Proxima Nova;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }

  .arrow {
    padding: 3px 0px;
  }

  .arrow span svg {
    font-size: 16px !important;
  }
  div.react-datepicker-popper {
    z-index: 2;
  }
  .react-datepicker-popper p,
  .react-datepicker-popper h1,
  .react-datepicker-popper div,
  .react-datepicker-popper h2,
  .react-datepicker__day-name {
    font-size: 14px !important;
  }
}

/* TABLET */
@media (min-width: 600px) {
  body,
  html {
    font-size: 11px !important;
    font-family: 'Proxima Nova' !important;
  }
  input {
    font-family: 'Proxima Nova' !important;
    font-size: 1.5rem !important;
  }
}

/* DESKTOP */
@media (min-width: 1024px) {
  body,
  html {
    font-size: 12px !important;
  }
  input {
    font-family: 'Proxima Nova' !important;
    font-size: 1.5rem !important;
  }

  div.react-datepicker__month-wrapper {
    min-width: 230px !important;
  }

  div.react-datepicker__year div.react-datepicker__year-wrapper {
    min-width: 230px !important;
  }
}

body {
  margin: 0;
  padding: 0 !important;
  font-family: 'Proxima Nova', -apple-system, 'Roboto';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f5f5f5; */

  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* DATEPICKER CUSTOMIZATION */

div.react-datepicker__input-time-container {
  margin: 0px !important;
}

div.react-datepicker-time__caption {
  display: none !important;
}

div.react-datepicker-time__input-container {
  width: 100% !important;
}

div.react-datepicker-time__input {
  width: 100% !important;
  margin: 0px !important;
}

.react-datepicker {
  background: #f9f9f9 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 10px !important;
}

.react-datepicker__input-container {
  position: inherit !important;
}

.react-datepicker__day-names,
.react-datepicker__header {
  background-color: #f9f9f9 !important;
  border: none !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.react-datepicker__month {
  margin-top: 0px !important;
}

.react-datepicker__current-month {
  margin-bottom: 10px;
}

.react-datepicker__header div {
  color: #055293 !important;
  font-size: 14px;
}

.react-datepicker__navigation-icon::before {
  border-color: #055293 !important;
  border-width: 2.5px 2.5px 0 0 !important;
  height: 3px !important;
  width: 3px !important;
  top: 8px !important;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  background-color: transparent;
}

.react-datepicker__day-names .react-datepicker__day-name {
  visibility: hidden;
  background-color: #f9f9f9;
  font-size: 0px;
  width: 2.413rem !important;
  text-transform: capitalize;
}

.react-datepicker__day-names .react-datepicker__day-name::first-letter {
  visibility: visible;
  color: #3ca70d;
  font-size: 12px;
  line-height: 1.3rem !important;
}

.react-datepicker__day {
  color: #055293 !important;
  font-size: 12px !important;
  line-height: 1.5rem !important;
  width: 2.413rem !important;
}

.react-datepicker__day--selected {
  color: #f9f9f9 !important;
  background-color: #3ca70d !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #f9f9f9 !important;
}

.react-datepicker__month.react-datepicker__monthPicker .react-datepicker__month-text {
  font-family: Proxima Nova;
  font-size: 1.16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: left;
  color: #055293;
  text-transform: capitalize;
  width: 6rem;
  text-align: center;
}

.react-datepicker__month.react-datepicker__monthPicker
  .react-datepicker__month-text--keyboard-selected {
  background-color: transparent !important;
  font-weight: bold !important;
  color: #055293;
}

.react-datepicker__month-text--selected {
  background-color: transparent !important;
  font-weight: bold !important;
  color: #055293;
}

.react-datepicker__year .react-datepicker__year-wrapper {
  justify-content: center;
}

.react-datepicker__year .react-datepicker__year-text {
  font-family: Proxima Nova;
  font-size: 1.16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: left;
  color: #055293;
  text-transform: capitalize;
  width: 5rem !important;
  text-align: center;
}

.react-datepicker__year-text--keyboard-selected {
  background: transparent !important;
  background-color: transparent !important;
  font-weight: bold !important;
  color: #055293;
}

.react-datepicker__year-text--selected {
  background: transparent !important;
  background-color: transparent !important;
  font-weight: bold !important;
  color: #055293;
}

.react-datepicker__day--outside-month {
  color: #bdbdbd !important;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #EEEDED inset !important;
}

:-webkit-autofill::first-line {
  color: #055293 !important;
  font-size: 1.5rem;
} */

::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5; 
}

::-webkit-scrollbar-track {
  background-color: #eaeaea; 
  border-radius: 10px; 
}

::-webkit-scrollbar-thumb {
  background-color: #b5b5b5; 
  border-radius: 10px; 
  border: 3px solid #eaeaea; 
}

::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}
